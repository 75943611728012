import React, { useState, useEffect } from "react";
import { Button, Container, Row, Col, Image, Form } from "react-bootstrap";
import Rating from "react-rating";
import { FaStar } from "react-icons/fa";
import Confetti from "react-confetti";
import { logModuleFeedback } from "../utils/apiUtils";
import "./Module.css";

const ModuleEnd = ({ handleCelebDone }) => {
  const [satisfaction, setSatisfaction] = useState(0); // Using zero here to map out "non-answers"
  const [showConfetti, setShowConfetti] = useState(true);
  const [confettiPieces, setConfettiPieces] = useState(600); // Initial number of confetti pieces
  const [confettiOpacity, setConfettiOpacity] = useState(1); // Initial opacity for the confetti

  useEffect(() => {
    // Stop showing confetti pieces after 5 seconds and start fading out
    const timer = setTimeout(() => {
      const interval = setInterval(() => {
        setConfettiPieces((prev) => Math.max(prev - 30, 0)); // Reduce the number of pieces gradually
        setConfettiOpacity((prev) => Math.max(prev - 0.05, 0)); // Reduce the opacity gradually
      }, 100);

      // Clear the interval after the confetti is fully faded out
      setTimeout(() => clearInterval(interval), 3000);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const handleSatisfactionChange = (value) => {
    setSatisfaction(value);
  };

  const handleContinue = async () => {
    try {
      const koulutusavainObj = JSON.parse(
        localStorage.getItem("koulutusAvain")
      );
      const koulutusavain_id = koulutusavainObj?.id;
      const currentModule = parseInt(localStorage.getItem("currentModule"), 10);
      const module = currentModule + 1;

      if (!koulutusavain_id || isNaN(currentModule)) {
        throw new Error("Invalid data in localStorage");
      }

      console.log(`${koulutusavain_id}, ${module}, ${satisfaction}`);

      if (satisfaction === 0) {
        handleCelebDone();
        return;
      }

      const response = await logModuleFeedback(
        koulutusavain_id,
        module,
        satisfaction
      );
      console.log(response);

      handleCelebDone();
    } catch (error) {
      console.error("Error submitting feedback:", error);
      handleCelebDone();
    }
  };

  return (
    <Container className="module-end" id="certificate-content">
      {showConfetti && (
        <Confetti
          numberOfPieces={confettiPieces}
          initialVelocityX={6}
          style={{ opacity: confettiOpacity }}
        />
      )}
      <Row className="text-center">
        <Col>
          <h1>Onnittelut! 🥳</h1>
          <p>Moduuli meni läpi niin, että heilahti. Erinomaista!</p>
        </Col>
      </Row>
      <Row className="image-row">
        <Col>
          <Image
            src="/CelebPhoto-AI.png"
            alt="Congratulations"
            fluid
            className="full-width-image"
          />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs="auto">
          <Form.Group controlId="formSatisfaction">
            <Form.Label className="satisfaction-label">
              <strong>Mitä pidit moduulista?</strong>
            </Form.Label>
            <div className="rating-container">
              <Rating
                initialRating={satisfaction}
                onChange={handleSatisfactionChange}
                emptySymbol={<FaStar color="#ccc" size={30} />}
                fullSymbol={<FaStar color="#ffd700" size={30} />}
              />
            </div>
          </Form.Group>
        </Col>
      </Row>
      <Row className="justify-content-center exclude-from-pdf">
        <Col xs="auto">
          <Button
            variant="dark"
            onClick={handleContinue}
            className="continue-button"
          >
            Jatka seuraavaan moduuliin
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default ModuleEnd;
