import React from "react";
import { Button } from "react-bootstrap";

export const ChapterNav = ({
  currentModule,
  currentChapter,
  handlePrevChapter,
  onComplete,
  handleNextChapter,
  chapterComplete,
}) => {
  return (
    <div className="module-nav">
      <Button
        className="hide-in-mobile"
        onClick={handlePrevChapter}
        variant="primary"
        disabled={currentModule === 0 && currentChapter === 0}
      >
        Edellinen
      </Button>

      <Button onClick={onComplete} variant="primary" disabled={chapterComplete}>
        {chapterComplete ? "Olet suorittanut osion" : "Merkitse suoritetuksi"}
      </Button>
      <Button
        className="hide-in-mobile"
        onClick={handleNextChapter}
        variant="primary"
      >
        Seuraava
      </Button>
    </div>
  );
};
