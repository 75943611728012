import React, { useState } from "react";
import { Container, Row } from "react-bootstrap";
import { ChapterNav } from "./ChapterNav";
import OsioBuilder from "./OsioBuilder";
import ModuleEnd from "./ModuleEnd"; // Import the new component
import "./Module.css";

const ModuleLayout = ({
  content,
  scope,
  currentModule,
  currentChapter,
  chapterComplete,
  onComplete,
  handlePrevChapter,
  handleNextChapter,
  setCompleted,
  showCeleb,
  handleCelebDone,
}) => {
  const [fastMode, setFastMode] = useState(false);

  const handleToggle = () => {
    setFastMode(!fastMode);
  };

  // Add safeguards to check if indices are within valid ranges
  const moduleExists = content[currentModule] !== undefined;
  const chapterExists =
    moduleExists && content[currentModule].osiot[currentChapter] !== undefined;

  if (!moduleExists) {
    return <div>Error: Module does not exist</div>;
    // Tämän pitää ohjata CurrentModule Chapter 0
  }

  if (!chapterExists) {
    return <div>Error: Osio does not exist</div>;
    // Tämän pitää ohjata CurrentModule Chapter 0
  }

  return (
    <Container fluid className="module-container">
      <Row>
        <p>
          <strong>
            Moduuli {content[currentModule].id}: {content[currentModule].name}
          </strong>
        </p>
      </Row>

      <Row className="module-text">
        {/*
        <div className="content-toggle" onClick={handleToggle}>
          <strong>{fastMode ? "+" : "-"}</strong>
        </div>
       */}

        {showCeleb ? (
          <ModuleEnd handleCelebDone={handleCelebDone} />
        ) : (
          <OsioBuilder
            fields={content[currentModule].osiot[currentChapter].fields}
          />
        )}
      </Row>

      {!showCeleb && (
        <ChapterNav
          currentModule={currentModule}
          currentChapter={currentChapter}
          handlePrevChapter={handlePrevChapter}
          onComplete={onComplete}
          handleNextChapter={handleNextChapter}
          chapterComplete={chapterComplete}
        />
      )}

      <div className="spacer">
        <p></p>
      </div>
    </Container>
  );
};

export default ModuleLayout;
