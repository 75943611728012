import React from "react";
import { Container, Row, Col, Image as BootstrapImage } from "react-bootstrap";
import "./ContentTypes.css";

const ImageStack = ({ stackObj }) => {

  return (
      <Row className="image-stack">
        {stackObj.images.map((image, index) => {
            const description = image.fields.description || "";
            const [title, ...descriptionParts] = description.split(';');
            const descriptionText = descriptionParts.join(';').trim();

            return (
              <Col key={index} lg={4} className="text-center">
                <BootstrapImage src={"https://" + image.fields.file.url} className="osioImage"/>
                {description && (
                  <div>
                    <h5>{title.trim()}</h5>
                    <p>{descriptionText}</p>
                  </div>
                )}
              </Col>
            );
        })}
      </Row>
  );
};

export default ImageStack;