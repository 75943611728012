import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, ListGroup, Button } from "react-bootstrap";
import ModuleLayout from "./components/ModuleLayout";
import { MobileNav } from "./components/MobileNav";
import { fetchLayoutsWithSections } from "./utils/contentfulApiUtils";

const Course = ({ scope, handleClear, setCompleted }) => {
  const navigate = useNavigate();
  const [currentModule, setCurrentModule] = useState(0);
  const [currentChapter, setCurrentChapter] = useState(0);
  const [completed, setCompletedModules] = useState([]);
  const [content, setContent] = useState({});
  const [loading, setLoading] = useState(true);
  const [showCeleb, setShowCeleb] = useState(false);

  useEffect(() => {
    const currentModule = JSON.parse(localStorage.getItem("currentModule"));
    const currentChapter = JSON.parse(localStorage.getItem("currentChapter"));
    const savedCompleted = JSON.parse(localStorage.getItem("completed"));
    const savedCourseCompleted =
      localStorage.getItem("courseCompleted") === "true";

    if (currentModule !== null) setCurrentModule(currentModule);
    if (currentChapter !== null) setCurrentChapter(currentChapter);
    if (savedCompleted !== null) setCompletedModules(savedCompleted);

    setCompleted(savedCourseCompleted);

    const getContentFromContentful = async (scope) => {
      try {
        const fetchedContent = await fetchLayoutsWithSections(scope);
        setContent(fetchedContent);
        setLoading(false);
      } catch (error) {
        console.error(
          "Error fetching layouts and sections in component:",
          error
        );
      }
    };
    getContentFromContentful(scope);

    window.scrollTo(0, 0);
  }, [scope, setCompleted]);

  const saveProgress = (module, chapter) => {
    setCurrentModule(module);
    setCurrentChapter(chapter);
    localStorage.setItem("currentModule", JSON.stringify(module));
    localStorage.setItem("currentChapter", JSON.stringify(chapter));
    const contentElement = document.getElementById("scroller");
    if (contentElement) contentElement.scrollTo(0, 0);
  };

  const checkIfCompletedModule = (module) => {
    let totalCompletedChapters = 0;
    for (let i = 0; i <= module; i++) {
      totalCompletedChapters += content[i].osiot.length;
    }
    return completed.length >= totalCompletedChapters;
  };

  const checkIfCompletedChapter = (tuple) =>
    completed.some((c) => c[0] === tuple[0] && c[1] === tuple[1]);

  const handleModuleClick = (module, isCompleted, isCurrent, isLocked) => {
    if (showCeleb) setShowCeleb(false);

    if (isCurrent || isCompleted) {
      saveProgress(module, 0);
    } else if (isLocked) {
      alert(`Suorita aiemmat osiot loppuun edetäksesi.`);
    } else {
      saveProgress(module, 0);
    }
  };

  const handleCompleteChapter = () => {
    const totalChapters = content[currentModule].osiot.length;
    const totalModules = content.length;
    const isLast =
      currentModule === totalModules - 1 &&
      currentChapter === totalChapters - 1;

    let newCompleted;

    if (isLast) {
      newCompleted = [...completed, [currentModule, currentChapter]];
      newCompleted = newCompleted.filter(
        (tuple, index, self) =>
          index ===
          self.findIndex((t) => t[0] === tuple[0] && t[1] === tuple[1])
      );
      localStorage.setItem("completed", JSON.stringify(newCompleted));
      setCompletedModules(newCompleted);
      setCompleted(true);
      localStorage.setItem("courseCompleted", "true");
      navigate("/completed");
    } else if (currentChapter < totalChapters - 1) {
      const newChapter = currentChapter + 1;
      newCompleted = [...completed, [currentModule, currentChapter]];
      newCompleted = newCompleted.filter(
        (tuple, index, self) =>
          index ===
          self.findIndex((t) => t[0] === tuple[0] && t[1] === tuple[1])
      );
      localStorage.setItem("completed", JSON.stringify(newCompleted));
      setCompletedModules(newCompleted);
      saveProgress(currentModule, newChapter);
    } else {
      newCompleted = [...completed, [currentModule, currentChapter]];
      newCompleted = newCompleted.filter(
        (tuple, index, self) =>
          index ===
          self.findIndex((t) => t[0] === tuple[0] && t[1] === tuple[1])
      );
      localStorage.setItem("completed", JSON.stringify(newCompleted));
      setCompletedModules(newCompleted);
      setShowCeleb(true);
    }
  };

  const handlePrevChapter = () => {
    if (currentChapter === 0) {
      const newModule = currentModule - 1;
      const totalChapters = content[newModule].osiot.length - 1;
      saveProgress(newModule, totalChapters);
    } else {
      const newChapter = currentChapter - 1;
      saveProgress(currentModule, newChapter);
    }
  };

  const handleNextChapter = () => {
    const totalChapters = content[currentModule].osiot.length;
    const totalModules = content.length;
    const isLast =
      currentModule === totalModules - 1 &&
      currentChapter === totalChapters - 1;

    // Etsii nykyisen moduulin suoritetut osiot
    const completedChaptersForCurrentModule = completed
      .filter((tuple) => tuple[0] === currentModule)
      .map((tuple) => tuple[1]);

    // Etsii arraysta moduulin seuraavaksi suoritettavan osion, eli arrayn suurimman numeron
    const nextChapterToComplete =
      Math.max(...completedChaptersForCurrentModule) + 1 < 1
        ? 0
        : Math.max(...completedChaptersForCurrentModule) + 1;

    // Jos nykyinen täytyy suorittaa ennen jatkoa = alert
    if (currentChapter === nextChapterToComplete) {
      alert(`Suorita osio loppuun edetäksesi.`);

      // Jos on seuraava osio, joka on aiempi kuin seuraavaksi suoritettava = päästä eteenpäin
    } else if (currentChapter < totalChapters - 1) {
      const newChapter = currentChapter + 1;
      saveProgress(currentModule, newChapter);

      // Jos on kurssin viimeinen osio = siirry päätössivulle
    } else if (isLast) {
      navigate("/completed");

      // Viimeinen vaihtoehto, että moduulin viimeinen = siirry seuraavan moduulin ensimmäiseen
    } else {
      const newModule = currentModule + 1;
      saveProgress(newModule, 0);
    }
  };

  const handleClearCompleted = () => {
    localStorage.removeItem("completed");
    setCompletedModules([]);
  };

  const handleAdminTools = () => navigate("/admin");

  const handleCelebDone = () => {
    setShowCeleb(false);
    const newModule = currentModule + 1;
    saveProgress(newModule, 0);
  };

  if (loading) return <p>Loading</p>;

  return (
    <Container fluid className="full-height">
      <Row className="content" id="scroller">
        <Col md={3} className="d-none d-md-block bg-light sidebar h-100">
          <div className="sidebar-sticky text-center">
            <div className="branding">
              <h4>Talouskoulutus artisteille</h4>
            </div>
            <ListGroup>
              {content.map((module, index) => {
                const moduleNumber = index;
                const isCompleted = checkIfCompletedModule(index);
                const isCurrent = moduleNumber === currentModule;
                const isLocked = !checkIfCompletedModule(index - 1);
                return (
                  <ListGroup.Item
                    key={index}
                    action
                    onClick={() =>
                      handleModuleClick(
                        moduleNumber,
                        isCompleted,
                        isCurrent,
                        isLocked
                      )
                    }
                    className={`module-item ${
                      isCompleted ? "completed" : isCurrent ? "current" : ""
                    } ${isLocked ? "locked" : ""}`}
                  >
                    {moduleNumber + 1}: {module.name} {isCompleted ? "✔" : ""}
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </div>
          <div className="copyright">
            {scope === 3 ? (
              <>
                <Button
                  size="sm"
                  variant="info"
                  className="mb-2"
                  onClick={handleAdminTools}
                >
                  Admin Tools
                </Button>
                <br />
                <Button
                  size="sm"
                  variant="info"
                  className="mb-2"
                  onClick={handleClearCompleted}
                >
                  Tyhjennä suoritetut
                </Button>
                <br />
                <Button
                  size="sm"
                  variant="danger"
                  className="mb-2"
                  onClick={handleClear}
                >
                  Tyhjennä koko välimuisti
                </Button>
              </>
            ) : null}
            <p>
              <small>© Hook Finland 2024</small>
            </p>
          </div>
        </Col>
        <Col sm={12} md={9} className="ml-sm-auto px-4 h-100">
          <ModuleLayout
            content={content}
            scope={scope}
            currentModule={currentModule}
            currentChapter={currentChapter}
            chapterComplete={checkIfCompletedChapter([
              currentModule,
              currentChapter,
            ])}
            onComplete={handleCompleteChapter}
            handlePrevChapter={handlePrevChapter}
            handleNextChapter={handleNextChapter}
            setCompleted={setCompletedModules}
            showCeleb={showCeleb}
            handleCelebDone={handleCelebDone}
          />
        </Col>
      </Row>
      <MobileNav
        currentModule={currentModule}
        currentChapter={currentChapter}
        chapterComplete={checkIfCompletedChapter([
          currentModule,
          currentChapter,
        ])}
        handlePrevChapter={handlePrevChapter}
        handleNextChapter={handleNextChapter}
      />
    </Container>
  );
};

export default Course;
