import React from "react";
import Heading from "./ContentTypes/Heading";
import InfoBox from "./ContentTypes/InfoBox";
import RichText from "./ContentTypes/RichText";
import Video from "./ContentTypes/Video";
import Image from "./ContentTypes/Image";
import ImageStack from "./ContentTypes/ImageStack";
import Youtube from "./ContentTypes/Youtube";

const OsioBuilder = ({ fields }) => {
  return (
    <>
      {
        // mapataan fieldit läpi ja tehdään componentit titlen perusteella

        fields.map((field, index) => {
          const titleExists = field?.title !== undefined;
          if (!titleExists) {
            return <p>Error rendering fields. No "field.title" found</p>;
          }

          if (field.title === "Heading") {
            return <Heading heading={field.heading} key={index} />;
          }

          if (field.title === "Infobox") {
            return <InfoBox richTextDocument={field.content} key={index} />;
          }

          if (field.title === "Image") {
            return (
              <Image
                imgObj={field}
                imgSrc={"https://" + field.imageFile.fields.file.url}
                key={index}
              />
            );
          }

          if (field.title === "ImageStack") {
            return <ImageStack stackObj={field} key={index} />;
          }

          if (field.title === "Rich text") {
            return <RichText richTextDocument={field.richText} key={index} />;
            
          } else if (field.title === "Video") {

            if (field.youtubeUrl) {
              return (
                <Youtube
                  videoObject={field}
                  videoId={toString(index)}
                  key={index}
                />
              );
            } else {
              return (
                <Video
                  videoObject={field}
                  videoId={toString(index)}
                  key={index}
                />
              );
            }
          } else {
            return <p key={index}>{field.title}</p>;
          }
        })
      }
    </>
  );
};

export default OsioBuilder;
