import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { checkKey } from '../utils/apiUtils';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LandingPage = ({ setKoulutusAvain, setScope, setHasKoulutusAvain }) => {
  const [input, setInput] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const result = await checkKey(input);
      let newData = { ...result.data, scope: parseInt(result.data.scope) };

      //TOAST SUCCESS
      toast.success('Koulutus-avain hyväksytty!');

      localStorage.setItem('scope', newData.scope);
      localStorage.setItem('koulutusAvain', JSON.stringify(newData));

      // Delay state updates and navigation to be able to show 'success'
      setTimeout(() => {
        setScope(newData.scope); //Tähän sitten bäkkäristä oikea scope, eli 1 vai kaikki moduulit
        setKoulutusAvain(newData);
        setHasKoulutusAvain(true);
      }, 1000); // 1-second delay to allow toast to show
    } catch (err) {
      //TOAST ERROR
      toast.error(`${err.message}`);
    }
  };

  return (
    <Container
      fluid
      className='d-flex flex-column justify-content-center align-items-center vh-100'
    >
      <ToastContainer />
      <Row className='w-100'>
        <Col md={{ span: 6, offset: 3 }} className='text-center'>
          <h2>Anna kertakäyttöinen koulutus-avain</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId='formBasicEmail' className='my-4'>
              <Form.Control
                type='text'
                placeholder='Syötä koulutus-avain'
                value={input}
                onChange={(e) => setInput(e.target.value)}
              />
            </Form.Group>
            <Button variant='primary' type='submit' className='w-100'>
              Siirry koulutukseen
            </Button>
          </Form>
          <p className='mt-4'>
            Koulutusavain ja eteneminen koulutuksessa tallentuu tähän selaimeen.
            Käynnistä koulutus selaimella, jolla aiot suorittaa koko
            koulutuksen.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default LandingPage;
