// src/components/Admin.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import KeyTable from "./components/Admin/KeyTable";
import CreateKeysForm from "./components/Admin/CreateKeysForm";
import { Navbar, Nav, Container, Row, Col, Button } from "react-bootstrap";
import { getKeys } from "./utils/apiUtils";

const Admin = () => {
  const navigate = useNavigate();
  const [studentEmail, setStudentEmail] = useState("");
  const [groupEmail, setGroupEmail] = useState("");
  const [search, setSearch] = useState();
  const [keyInfo, setKeyInfo] = useState({
    course: "",
    creation_date: "",
    finishedDate: null,
    id: "",
    koulutusavain: "",
    scope: "",
    student_email: "",
    usedDate: null,
  });
  const [scope, setScope] = useState(1);
  const [amount, setAmount] = useState(1);
  const [keys, setKeys] = useState([]);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    try {
      const fetchedContent = await getKeys();
      setKeys(fetchedContent);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleSingleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const response = await fetch(
        "https://koulutus.hookfinland.fi/api/create-single.php",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ student_email: studentEmail, scope }),
        }
      );
      const result = await response.json();
      getData();
      alert(result.message);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleMultipleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    /*try {
      const response = await fetch(
        "https://koulutus.hookfinland.fi/api/create-multiple.php",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ group_email: groupEmail, scope, amount }),
        }
      );
      const result = await response.json();
      getData();
      alert(result.message);
    } catch (error) {
      console.error("Error:", error);
    }*/
  };

  const handleFind = async (e) => {
    e.preventDefault();
    let body;
    if (search.includes("@")) {
      body = JSON.stringify({ type: "email", search });
    } else {
      body = JSON.stringify({ type: "key", search });
    }

    try {
      const response = await fetch(
        "https://koulutus.hookfinland.fi/api/search.php",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: body,
        }
      );
      const result = await response.json();
      setKeyInfo(result.data[0]);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleBack = () => {
    navigate("/");
  };

  return (
    <Container fluid className="admin-view">
      <Navbar bg="dark" data-bs-theme="dark">
        <Container>
          <Navbar.Brand href="#home">Admin tools</Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link onClick={handleBack}>Takaisin kurssiin</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <Container className="admin-content">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            <CreateKeysForm
              search={search}
              setSearch={setSearch}
              keyInfo={keyInfo}
              handleSingleSubmit={handleSingleSubmit}
              handleMultipleSubmit={handleMultipleSubmit}
              handleFind={handleFind}
              studentEmail={studentEmail}
              setStudentEmail={setStudentEmail}
              groupEmail={groupEmail}
              setGroupEmail={setGroupEmail}
              scope={scope}
              setScope={setScope}
              amount={amount}
              setAmount={setAmount}
            />
            <hr></hr>
            <Row className="mt-3">
              <Col>
                <div className="mb-3">
                  <h3>Active keys: Talouskoulutus artisteille</h3>
                </div>
                <KeyTable keys={keys} />
                <Button>Load more</Button>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </Container>
  );
};

export default Admin;
