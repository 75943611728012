import React, { useRef, useEffect } from "react";
import "./ContentTypes.css"

const Video = ({ videoObject, videoId }) => {
  const videoRef = useRef(null);
  // Check if files exists using optional chaining
  const videoFileExists = videoObject?.videoFile?.fields?.file !== undefined;
  const subtitleFileExists = videoObject?.subtitleFile?.fields?.file !== undefined;
  // Always prefer contentful-hosted videos/subs, but if there's none - use the videoUrl/subUrl
  let videoSrc = videoFileExists ? "https:" + videoObject.videoFile.fields.file.url : videoObject.videoUrl;
  let subtitleSrc = subtitleFileExists ? "https:" + videoObject.subtitleFile.fields.file.url : videoObject.subtitleUrl;
  
  // Load saved time from localStorage when component mounts
  useEffect(() => {
    const savedTime = localStorage.getItem(`video-${videoId}-time`);
    if (savedTime && videoRef.current) {
      videoRef.current.currentTime = parseFloat(savedTime);
    }
  }, [videoId]);

  // Save current time to localStorage periodically
  useEffect(() => {
    const handleTimeUpdate = () => {
      if (videoRef.current) {
        localStorage.setItem(
          `video-${videoId}-time`,
          videoRef.current.currentTime
        );
      }
    };

    const video = videoRef.current;
    video.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      video.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, [videoId]);

  return (
    <div>
      <video
        ref={videoRef}
        width="100%"
        controls
        playsInline
        className="video-player"
        src={videoSrc}
        type="video/mp4"
      >
        {subtitleSrc && (
          <track
            default
            kind="subtitles"
            srcLang="fi"
            src={subtitleSrc}
            label="Finnish"
          />
        )}
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default Video;