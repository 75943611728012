import React from "react";
import { Table } from "react-bootstrap";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import "./ContentTypes.css"

const RichText = ({ richTextDocument, index }) => {
  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    },
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
      [BLOCKS.TABLE]: (node, children) => <Table striped bordered hover><tbody>{children}</tbody></Table>,
      [BLOCKS.TABLE_ROW]: (node, children) => <tr>{children}</tr>,
      [BLOCKS.TABLE_CELL]: (node, children) => <td>{children}</td>,
      [BLOCKS.TABLE_HEADER_CELL]: (node, children) => <th>{children}</th>,
      // Add more custom rendering as needed
    },
  };

  return <div key={index}>{documentToReactComponents(richTextDocument, options)}</div>;
};

export default RichText;
