import React from "react";
import "./ContentTypes.css";

const extractYouTubeVideoId = (url) => {
  const regex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})/;
  const matches = url.match(regex);
  return matches ? matches[1] : null;
};

const Youtube = ({ videoObject, videoId }) => {
  const youtubeId = extractYouTubeVideoId(videoObject.youtubeUrl);

  return (
    <div className="youtube-player">
      <iframe
        src={`https://www.youtube.com/embed/${youtubeId}`}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default Youtube;
