import axios from "axios";
const url = "https://koulutus.hookfinland.fi/api/";

export const test = async (data) => {
  try {
    const response = await axios.post(url + "api.php", data);
    return response.data;
  } catch (error) {
    console.error("There was an error!", error);
    return "Error";
  }
};

export const checkKey = async (koulutusavain) => {
  try {
    const response = await axios.post(`${url}check-key.php`, {
      koulutusavain: koulutusavain,
    });
    if (!response.data.success) {
      throw new Error(response.data.message);
    }
    return response.data;
  } catch (error) {
    console.error("There was an error!", error);
    throw new Error(error);
  }
};

export const login = async (data) => {
  try {
    const response = await axios.post(url + "login.php", data);
    return response.data;
  } catch (error) {
    console.error("There was an error!", error);
    return "Error";
  }
};

export const getUser = async () => {
  try {
    const response = await axios.get(url + "api.php");
    console.log(response.data);
    const username = response.data.data[0].USERNAME; // Assuming the USERNAME is stored under the key 'USERNAME'
    return username; // This value will be returned by the async function
  } catch (error) {
    console.error("Error fetching data:", error);
    return "Error"; // This value will be returned by the async function in case of error
  }
};

export const getUsers = async () => {
  try {
    const response = await axios.get(url + "getusers.php");
    return response.data; // This value will be returned by the async function
  } catch (error) {
    console.error("Error fetching data:", error);
    return "Error"; // This value will be returned by the async function in case of error
  }
};

export const logModuleFeedback = async (id, module, stars) => {
  try {
    const response = await axios.post(`${url}module-feedback.php`, {
      koulutusavain_id: id,
      module_number: module,
      stars: stars
    });

    if (!response.data.success) {
      throw new Error(response.data.message);
    }

    return response.data;
  } catch (error) {
    console.error("There was an error!", error.message);
    throw new Error(error.response ? error.response.data.message : error.message);
  }
};

export const logCourseFeedback = async (id, stars, feedback, course) => {
  try {
    const response = await axios.post(`${url}course-feedback.php`, {
      koulutusavain_id: id,
      stars: stars,
      feedback: feedback,
      course: course
    });

    if (!response.data.success) {
      throw new Error(response.data.message);
    }

    return response.data;
  } catch (error) {
    console.error("There was an error!", error.message);
    throw new Error(error.response ? error.response.data.message : error.message);
  }
};

// --------------------- ADMIN -----------------------

export const getKeys = async () => {
  try {
    const response = await axios.post(url + "get-keys.php");
    return response.data;
  } catch (error) {
    console.error("There was an error!", error);
    return "Error";
  }
};