import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivateRoute from './utils/PrivateRoute.js';
import ProtectedRoute from './utils/ProtectedRoute.js';
import LandingPage from './components/LandingPage';
import Admin from './Admin';
import Course from './Course';
import Completed from './Completed.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Test from './components/Test.js';

const App = () => {
  const [hasKoulutusAvain, setHasKoulutusAvain] = useState(false);
  const [koulutusAvain, setKoulutusAvain] = useState({});
  const [scope, setScope] = useState(1);
  const [completed, setCompleted] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const savedKoulutusAvain = localStorage.getItem('koulutusAvain');
    const savedScope = localStorage.getItem('scope');
    const savedCompleted = localStorage.getItem('courseCompleted') === 'true';

    setScope(savedScope ? parseInt(savedScope) : 1);
    setHasKoulutusAvain(!!savedKoulutusAvain);
    setCompleted(savedCompleted);
    setLoading(false); // Set loading to false after loading the completion status
  }, []);

  const handleClear = () => {
    localStorage.clear();
    setHasKoulutusAvain(false);
    setCompleted(false);
  };

  if (loading) {
    return <div>Loading...</div>; // Add a loading state to delay rendering until the completion status is loaded
  }

  return (
    <Router>
      <Routes>
        <Route
          path='/admin'
          element={<PrivateRoute component={Admin} scope={scope} />}
        />
        <Route path='/test' element={<Test />} />

        <Route
          path='*'
          element={
            !hasKoulutusAvain ? (
              <LandingPage
                setKoulutusAvain={setKoulutusAvain}
                setScope={setScope}
                setHasKoulutusAvain={setHasKoulutusAvain}
              />
            ) : (
              <Course
                scope={parseInt(scope)}
                handleClear={handleClear}
                setCompleted={setCompleted}
              />
            )
          }
        />
        <Route
          path='/completed'
          element={
            <ProtectedRoute
              component={Completed}
              completed={completed}
              scope={scope}
            />
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
