// src/components/Admin/AdminForm.js
import React from "react";
import { Table, Form, Button, Row, Col } from "react-bootstrap";
import "./Admin.css";

const CreateKeysForm = ({
  search,
  setSearch,
  keyInfo,
  handleSingleSubmit,
  handleMultipleSubmit,
  handleFind,
  studentEmail,
  setStudentEmail,
  groupEmail,
  setGroupEmail,
  scope,
  setScope,
  amount,
  setAmount,
}) => {

  return (
    <>
      <Row>
        <Col>
          <Form onSubmit={handleFind}>
            <h5>Etsi tiedot koulutusavaimella</h5>
            <Form.Group as={Row} controlId="formFind">
              <Form.Label column sm={2}>
                Koulutusavain
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  type="text"
                  placeholder="Koulutusavain tai sähköposti"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  required
                />
              </Col>
              <Col sm={6}>
                <Button variant="primary" type="submit">
                  Etsi
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <Row className="mt-3">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Email</th>
              <th>Luotu</th>
              <th>Koulutusavain</th>
              <th>Scope</th>
              <th>Toimenpiteet</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{keyInfo.id}</td>
              <td>{keyInfo.student_email}</td>
              <td>{keyInfo.creation_date}</td>
              <td>{keyInfo.koulutusavain}</td>
              <td>{keyInfo.scope}</td>
              <td>
                <Button variant="secondary" size="sm" className="table-button">
                  Reset
                </Button>
                <Button variant="secondary" size="sm" className="table-button">
                  Re-send
                </Button>
                <Button variant="danger" size="sm" className="table-button">
                  Revoke
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </Row>
      <hr></hr>

      <Row>
        <Col>
          <Form onSubmit={handleSingleSubmit}>
            <h5>Create Single Koulutusavain</h5>
            <Form.Group as={Row} controlId="formEmail" className="mb-2">
              <Form.Label column sm={4}>
                Student Email
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  type="email"
                  placeholder="Student Email"
                  value={studentEmail}
                  onChange={(e) => setStudentEmail(e.target.value)}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formScope" className="mb-2">
              <Form.Label column sm={4}>
                Scope
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  type="number"
                  placeholder="Scope"
                  value={scope}
                  onChange={(e) => setScope(parseInt(e.target.value))}
                  required
                />
              </Col>
            </Form.Group>
            <Button variant="primary" type="submit">
              Create
            </Button>
          </Form>
        </Col>

        <Col>
          <Form onSubmit={handleMultipleSubmit}>
            <h5>Create Multiple Koulutusavaimet</h5>
            <Form.Group as={Row} controlId="formBulkEmail" className="mb-2">
              <Form.Label column sm={4}>
                Group Email
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  type="email"
                  placeholder="Group Email"
                  value={groupEmail}
                  onChange={(e) => setGroupEmail(e.target.value)}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formBulkScope" className="mb-2">
              <Form.Label column sm={4}>
                Scope
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  type="number"
                  placeholder="Scope"
                  value={scope}
                  onChange={(e) => setScope(parseInt(e.target.value))}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formBulkAmount" className="mb-2">
              <Form.Label column sm={4}>
                Amount
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  type="number"
                  placeholder="Amount"
                  value={amount}
                  onChange={(e) => setAmount(parseInt(e.target.value))}
                  required
                />
              </Col>
            </Form.Group>
            <Button variant="primary" type="submit">
              Create Multiple
            </Button>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default CreateKeysForm;
