import React from "react";
import { Container, Row, Col, Image as BootstrapImage } from "react-bootstrap";
import "./ContentTypes.css";

const Image = ({ imgObj, imgSrc }) => {
  let horizontalAlign = "";
  if (imgObj.horizontalAlign === "Center") {
    horizontalAlign = "text-center";
  }

  return (
    <Container>
      <Row>
        <Col className={horizontalAlign} sm={3} md={3} lg={3} xl={3}>
          <BootstrapImage src={imgSrc} className="osioImage"/>
        </Col>
      </Row>
    </Container>
  );
};

export default Image;
