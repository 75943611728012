export const MobileNav = ({
  currentModule,
  currentChapter,
  chapterComplete,
  handlePrevChapter,
  handleNextChapter,
}) => {
  return (
    <div className="d-md-none fixed-bottom bg-light text-center footer-nav">
      <button
        onClick={handlePrevChapter}
        className="footer-nav-previous"
        disabled={currentModule === 0 && currentChapter === 0}
      >
        Edellinen
      </button>

      <button disabled className="footer-nav-current">
        {chapterComplete ? "Suoritettu" : "Kesken"}
      </button>

      <button onClick={handleNextChapter} className="footer-nav-next">
        Seuraava
      </button>
    </div>
  );
};
