// pdfUtils.js
import jsPDF from 'jspdf';

export const generateCertificatePDF = (imageSrc) => {
  const doc = new jsPDF();

  const img = new window.Image();
  img.src = imageSrc;

  img.onload = () => {
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const imgWidth = pageWidth - 40; // Leave some margin on the sides
    const imgHeight = (imgWidth * img.height) / img.width; // Maintain aspect ratio

    // Add background color
    doc.setFillColor(255, 255, 255); // White background
    doc.rect(0, 0, pageWidth, pageHeight, 'F');

    // Add title
    doc.setFont('Helvetica', 'bold');
    doc.setFontSize(22);
    doc.setTextColor(0, 0, 0);
    doc.text('Congratulations!', pageWidth / 2, 30, { align: 'center' });

    // Add subtitle
    doc.setFont('Helvetica', 'normal');
    doc.setFontSize(16);
    doc.text(
      'You just completed the first section of your course.',
      pageWidth / 2,
      45,
      { align: 'center' }
    );

    // Add image
    doc.addImage(img, 'PNG', 20, 60, imgWidth, imgHeight); // Position and size image

    // Add message
    doc.setFontSize(14);
    doc.setTextColor(0, 0, 0);
    doc.text(
      'You earned it! Take a moment to celebrate and share your progress.',
      pageWidth / 2,
      imgHeight + 80,
      { align: 'center' }
    );

    // Save PDF
    doc.save('certificate.pdf');
  };

  img.onerror = (error) => {
    console.error('Image failed to load:', error);
  };
};
