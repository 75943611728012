// utils/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, completed, ...rest }) => {
  console.log('ProtectedRoute - Completed:', completed);
  return completed ? <Component {...rest} /> : <Navigate to='/' />;
};

export default ProtectedRoute;
