import { createClient } from 'contentful';

// Initialize the Contentful client
const client = createClient({
  space: 'wytxyl1d42oy',
  accessToken: '2hgF5KqlgV9KkaitN8r09WbyxkYClKB03n_cqM6bkGU',
});

// Function to fetch layouts, sections and IDs
export const fetchLayoutsWithSections = async (scope) => {
  try {
    const response = await client.getEntries({
      content_type: 'osiolayout', // replace with any content type – lowercase always
      include: 10, // Deepness of the nestedness
    });

    //keeping to double check the original data from the response before re-structuring
    //console.log('ORIGINAL FETCH DATA: ', response);

    /*
    Structuring layouts and their content into one
    const layouts = response.items.map((layout) => ({
      layoutId: layout.sys.id,
      title: layout.fields.title,
      sections: layout.fields.sections.map((section) => ({
        id: section.sys.id,
        title: section.fields.title,
        content: section.fields,
      })),
    }));
    */

    /*
    Structuring just the ids into one
    const idArray = response.items.map((layout) => ({
      layoutId: layout.sys.id,
      sectionIds: layout.fields.sections.map((section) => section.sys.id),
    }));
    */

    // Packing data to be shared
    //const data = [{id: 1, title: "Musiikkiala", osiot: layouts}, {id: 2, title: "Tavoitteet & Tiimi", osiot: layouts}, {id: 3, title: "Budjetointi", osiot: layouts}]

    const transformArray = (items) => {
      // Step 1: Extract relevant information and map the items
      const uniqueTitlesMap = items.reduce((acc, item) => {
        const [id, prefix, restTitle] = item.fields.title
          .split(';')
          .map((str) => str.trim());
        const formattedTitle = restTitle.split(' ')[0]; // Extracting the first part before the space
        const osiId = parseInt(formattedTitle.split('.')[1]); // Extracting the second number as the osiId

        if (!acc[prefix]) {
          acc[prefix] = { id: parseInt(id), name: prefix, osiot: [] };
        }
        acc[prefix].osiot.push({
          id: osiId,
          title: restTitle,
          fields: item.fields.sections.map((section) => section.fields),
        });
        return acc;
      }, {});

      // Step 2: Convert the map to an array of objects
      let transformedArray = Object.entries(uniqueTitlesMap).map(
        ([name, { id, osiot }]) => ({
          id,
          name,
          osiot: osiot.sort((a, b) => a.id - b.id), // Sort osiot array by id
        })
      );

      // Step 3: Sort the transformed array by id
      transformedArray = transformedArray.sort((a, b) => a.id - b.id);

      // Step 4: Return one, or all, modules based on scope provided from App.js
      if (scope === 1) {
        return [transformedArray[0]];
      }

      return transformedArray;
    };

    const data = transformArray(response.items);
    return data;
  } catch (error) {
    console.error('Error fetching layouts and sections:', error);
    return []; // Return an empty array in case of an error
  }
};

export const testFeedback = async () => {
  const response = await client.getEntries({
    content_type: 'feedback', // replace with any content type – lowercase always
    include: 10, // Deepness of the nestedness
  });

  return response;
};
