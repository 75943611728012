import React, { useState } from "react";
import { Table, Button } from "react-bootstrap";
import "./Admin.css";

const KeyTable = ({ keys }) => {
  const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'ascending' });

  const handleAction = (id, action) => {
    console.log(id + ", " + action);
  };

  const sortedKeys = React.useMemo(() => {
    let sortableItems = [...keys.data];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [keys.data, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? '▲' : '▼';
    }
    return '';
  };

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th onClick={() => requestSort('id')}>ID {getSortIndicator('id')}</th>
          <th onClick={() => requestSort('student_email')}>Email {getSortIndicator('student_email')}</th>
          <th onClick={() => requestSort('creation_date')}>Luotu {getSortIndicator('creation_date')}</th>
          <th onClick={() => requestSort('koulutusavain')}>Koulutusavain {getSortIndicator('koulutusavain')}</th>
          <th onClick={() => requestSort('scope')}>Scope {getSortIndicator('scope')}</th>
          <th>Toimenpiteet</th>
        </tr>
      </thead>
      <tbody>
        {sortedKeys.map((key, index) => (
          <tr key={index}>
            <td>{key.id}</td>
            <td>{key.student_email}</td>
            <td>{key.creation_date}</td>
            <td>{key.koulutusavain}</td>
            <td>{key.scope}</td>
            <td>
              <Button
                variant="secondary"
                size="sm"
                className="table-button"
                onClick={() => handleAction(key.id, "Reset")}
              >
                Reset
              </Button>
              <Button
                variant="secondary"
                size="sm"
                className="table-button"
                onClick={() => handleAction(key.id, "Re-send")}
              >
                Re-send
              </Button>
              <Button
                variant="danger"
                size="sm"
                className="table-button"
                onClick={() => handleAction(key.id, "Revoke")}
              >
                Revoke
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default KeyTable;