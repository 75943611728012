import React, { useState, useEffect } from 'react';
import { testFeedback } from '../utils/contentfulApiUtils'; // Ensure this path is correct
import { Form, Button } from 'react-bootstrap';
import { FaStar } from 'react-icons/fa';
import Rating from 'react-rating';
import './Test.css'; // Import the CSS file

const Test = () => {
  const [feedbackGiven, setFeedbackGiven] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [satisfaction, setSatisfaction] = useState(3); // Default feedback is 3 (neutral)
  const [comments, setComments] = useState('');
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await testFeedback();
        setData(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [data]);

  const handleFeedbackSubmit = async (event) => {
    event.preventDefault();
    try {
      const feedbackData = {
        name,
        email,
        satisfaction: parseInt(satisfaction),
        comments,
      };
      console.log(feedbackData);
      setFeedbackGiven(true);
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSatisfactionChange = (value) => {
    setSatisfaction(value);
  };

  const handleCommentsChange = (event) => {
    setComments(event.target.value);
  };

  if (feedbackGiven) {
    return <div>Thank you for your feedback!</div>;
  }

  return (
    <div className='feedback-component'>
      <h3>How did you like the content?</h3>
      <Form onSubmit={handleFeedbackSubmit}>
        <Form.Group controlId='formName'>
          <Form.Label>Your Name</Form.Label>
          <Form.Control
            type='text'
            value={name}
            onChange={handleNameChange}
            placeholder='Enter your name'
          />
        </Form.Group>

        <Form.Group controlId='formEmail'>
          <Form.Label>Your Email</Form.Label>
          <Form.Control
            type='email'
            value={email}
            onChange={handleEmailChange}
            placeholder='Enter your email'
          />
        </Form.Group>

        <Form.Group controlId='formSatisfaction'>
          <Form.Label>Your Satisfaction: {satisfaction}</Form.Label>
          <div className='rating-container'>
            <Rating
              initialRating={satisfaction}
              onChange={handleSatisfactionChange}
              emptySymbol={<FaStar color='#ccc' size={30} />}
              fullSymbol={<FaStar color='#ffd700' size={30} />}
            />
          </div>
        </Form.Group>

        <Form.Group controlId='formComments'>
          <Form.Label>Additional Comments (optional)</Form.Label>
          <Form.Control
            as='textarea'
            rows={3}
            value={comments}
            onChange={handleCommentsChange}
            placeholder='Enter your comments'
          />
        </Form.Group>

        <Button variant='primary' type='submit'>
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default Test;
