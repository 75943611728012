import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import { logCourseFeedback } from "./utils/apiUtils";
import { generateCertificatePDF } from "./utils/pdfUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import "./components/ContentTypes/ContentTypes.css";

const Completed = ({ scope }) => {
  const navigate = useNavigate();
  const [rating, setRating] = useState("");
  const [feedback, setFeedback] = useState("");
  const [course, setCourse] = useState("");

  useEffect(() => {
    const koulutusAvain = JSON.parse(localStorage.getItem("koulutusAvain"));
    setCourse(koulutusAvain.course);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const koulutusavainObj = JSON.parse(
        localStorage.getItem("koulutusAvain")
      );
      const koulutusavain_id = koulutusavainObj?.id;

      if (!koulutusavain_id) {
        throw new Error("Invalid data in localStorage");
      }

      if (rating === 0) {
        return;
      }

      const response = await logCourseFeedback(
        koulutusavain_id,
        rating,
        feedback,
        course
      );
      console.log(response);
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  const handleToCourse = () => {
    navigate("/");
  };

  const handleGeneratePDF = () => {
    generateCertificatePDF("/CelebPhoto-AI.png");
  };

  return (
    <Container className="mt-5">
      <Row>
        <Col>
          {parseInt(scope) === 1 ? (
            <h1>
              Olet suorittanut 1. moduulin kurssista: <br></br> {course}
            </h1>
          ) : (
            <h1>Olet suorittanut kurssin {course}</h1>
          )}
          <div className="container mt-5">
            <h2>Give Your Feedback</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="rating">Rating (1-5 stars):</label>
                <input
                  type="number"
                  className="form-control"
                  id="rating"
                  name="rating"
                  min="1"
                  max="5"
                  value={rating}
                  onChange={(e) => setRating(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="feedback">Feedback:</label>
                <textarea
                  className="form-control"
                  id="feedback"
                  name="feedback"
                  rows="3"
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                  required
                ></textarea>
              </div>
              <button type="submit" className="btn btn-primary mt-2">
                Submit
              </button>
            </form>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center text-center">
        <Col md={8} className="course-end-message" onClick={handleGeneratePDF}>
          <FontAwesomeIcon icon={faDownload} className="download-icon" />
          <span className="message-text">
            Napsauta tästä suoritusmerkintä talteen.
          </span>
        </Col>
      </Row>
      <Row className="m-2">
        <Button>Lähetä todistus sähköpostiin</Button>
      </Row>

      <Row className="m-2">
        <Button onClick={handleToCourse}>
          Palaa kertaamaan kurssin sisältöjä
        </Button>
      </Row>
    </Container>
  );
};

export default Completed;
